export default {
  methods: {
    getLocalizedDate(date) {
      const d = date instanceof Date ? date : new Date(date);

      return d.toLocaleDateString('ru', { timeZone: 'Europe/Moscow' });
    },

    getLocalizedTime(date) {
      const d = date instanceof Date ? date : new Date(date);

      return d.toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit', timeZone: 'Europe/Moscow' });
    },
  },

};
