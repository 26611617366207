<template>
  <CCard body-wrapper>
    <CRow
      align-vertical="center"
      class="mb-3"
    >
      <CCol col="auto">
        <h1>
          Чек №{{ $route.params.id }}
        </h1>
      </CCol>
    </CRow>
    <CTabs
      class="mt-5"
      variant="tabs"
      :active-tab="0"
    >
      <CTab title="Список товаров">
        <CDataTable
          class="mt-5"
          :fields="linesTableFields"
          :header="true"
          :hover="true"
          :items="order.lines"
          :loading="loadingOrder"
          :striped="true"
        >
          <template #amount="{ item }">
            <td class="align-middle text-right">
              {{ getLocalizedAmount(item.amount) }}
            </td>
          </template>
          <template #loading>
            <CElementCover
              :opacity="0.8"
            >
              <CSpinner
                class="custom-spinner"
                color="info"
              />
            </CElementCover>
          </template>
          <template #no-items-view>
            <div class="text-center">
              Отсутствуют товары
            </div>
          </template>
          <template #quantity="{ item }">
            <td class="align-middle text-center">
              {{ getLocalizedNumber(item.quantity) }}
            </td>
          </template>
          <template #sku="{ item }">
            <td class="align-middle text-center">
              {{ item.sku }}
            </td>
          </template>
        </CDataTable>
      </CTab>
      <CTab :title="`Вебхуки (${order.webhooks.length})`">
        <CDataTable
          class="mt-5"
          :fields="webhooksTableFields"
          :header="true"
          :hover="true"
          :items="order.webhooks"
          :loading="loadingOrder"
          :striped="true"
        >
          <template #createdAt="{ item }">
            <td class="align-middle text-center">
              {{ getLocalizedDate(item.createdAt) }} {{ getLocalizedTime(item.createdAt) }}
            </td>
          </template>
          <template #loading>
            <CElementCover
              :opacity="0.8"
            >
              <CSpinner
                class="custom-spinner"
                color="info"
              />
            </CElementCover>
          </template>
          <template #no-items-view>
            <div class="text-center">
              Отсутствуют хуки
            </div>
          </template>
        </CDataTable>
      </CTab>
    </CTabs>
  </CCard>
</template>

<script>
import Swal from 'sweetalert2';
import AmountMixins from '../../../mixins/AmountMixins';
import DateTimeMixins from '../../../mixins/DateTimeMixins';
import NumberMixins from '../../../mixins/NumberMixins';
import urls from '../../../router/urls';

export default {
  name: 'Detail',

  mixins: [
    AmountMixins,
    DateTimeMixins,
    NumberMixins,
  ],

  data() {
    return {
      actionIsBeingPerformed: false,
      loadingOrder: false,
      linesTableFields: [
        {
          _classes: ['align-middle', 'text-center'],
          key: 'sku',
          label: 'Артикул',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'productName',
          label: 'Наименование',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'quantity',
          label: 'Количество',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'amount',
          label: 'Сумма, руб',
        },
      ],
      webhooksTableFields: [
        {
          _classes: ['align-middle', 'text-center'],
          key: 'createdAt',
          label: 'Дата создания',
        },
      ],
      order: {
        lines: [],
        webhooks: [],
      },
    };
  },

  created() {
    this.getOrder();
  },

  methods: {
    async getOrder() {
      this.loadingOrder = true;

      try {
        const { data } = await this.$axios.get(`${urls.urlGetOrders}/${this.$route.params.id}`);

        data.internal_order_lines.forEach(({
          amount, product_name: productName, quantity, sku,
        }) => {
          this.order.lines.push({
            amount,
            productName,
            quantity,
            sku,
          });
        });

        data.webhooks.forEach(({ created_at: createdAt }) => {
          this.order.webhooks.push({
            createdAt,
          });
        });
      } catch (e) {
        if (e.response) {
          await Swal.fire({
            icon: 'error',
            text: 'При загрузке чека возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику.',
            title: 'Ошибка!',
          });

          await this.$router.push({ name: 'admin.orders.list' });
        }
      } finally {
        this.loadingOrder = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
